import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { Button } from 'ui';
import { useSubscribeToNewsletterMutation } from '../../features/newsletter/mutations';
import { validateForm } from '../../utils/formUtil';
import FormError from '../Form/FormError/FormError';
import FormGroupInput from '../Form/FormGroupInput/FormGroupInput';
import PrivacyCheckBox from '../Form/PrivacyCheckBox/PrivacyCheckBox';
import Alert from '../Layout/Alert/Alert';
import NewsletterValidationSchema, { NewsletterValidationSchemaType } from './NewsletterValidationSchema';

const FORM_FIELDS = {
  EMAIL: 'email',
};

interface NewsletterFormProps {
  formId?: string;
  formType?: string;
}

const NewsletterForm = ({ formId = 'footer-newsletter-form', formType = '' }: NewsletterFormProps) => {
  const { formatMessage } = useIntl();

  const [showNewsletterMessage, setShowNewsletterMessage] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const {
    isError: isErrorSubscribe,
    isSuccess: isSuccessSubscribe,
    mutateAsync: subscribeMutation,
  } = useSubscribeToNewsletterMutation();

  const onSubmit = (values: NewsletterValidationSchemaType) => {
    if (values.email && values.terms_and_conditions) {
      setShowNewsletterMessage(false);
      setSubscriptionError(false);
      subscribeMutation({ email: values.email });
    }
  };

  useEffect(() => {
    if (isSuccessSubscribe) {
      setShowNewsletterMessage(true);
      setSubscriptionError(false);
    }
    if (isErrorSubscribe) {
      setShowNewsletterMessage(true);
      setSubscriptionError(true);
    }
  }, [isSuccessSubscribe, isErrorSubscribe]);

  return (
    <>
      {showNewsletterMessage && (
        <Alert type={subscriptionError ? 'danger' : 'success'}>
          {formatMessage({
            id: subscriptionError
              ? 'footer_subscribe_newsletter_subscription_error'
              : 'footer_subscribe_newsletter_subscription_success',
          })}
        </Alert>
      )}

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form id={formId} onSubmit={handleSubmit}>
            <FormError />

            <FormGroupInput
              aria-label={formatMessage({ id: 'newsletter_form_input_email' })}
              handleKeyUp={(e) => e.which === 13 && handleSubmit()}
              id={`${formId}_${FORM_FIELDS.EMAIL}_footerNewsLetterForm`}
              label={formatMessage({
                id: 'footer_newsletter_form_email_placeholder',
              })}
              name={FORM_FIELDS.EMAIL}
              type="email"
            />

            <PrivacyCheckBox
              formId={formId}
              formType={formType}
              termsAndConditions={formatMessage({
                id: 'footer_newsletter_form_accept_terms_and_conditions',
              })}
              termsAndConditionsLink={formatMessage({
                id: 'footer_newsletter_form_terms_and_conditions_link',
              })}
            />

            <Button className="w-full" variant="secondary" disabled={submitting} type="submit">
              {formatMessage({
                id: 'newsletter_block_btn_text',
              })}
            </Button>
          </form>
        )}
        validate={validateForm(NewsletterValidationSchema)}
      />
    </>
  );
};

export default NewsletterForm;
